import React from 'react';

const Text = ({ className, children, as: Component = 'div' }) => {
  return (
    <Component className={className}>
      {children}
    </Component>
  );
};

export default Text;