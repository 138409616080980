import Image from "../atoms/Image";
import Mural from "../../assets/mural-historia.png";
import Text from "../atoms/Text";

const OurHistory = () => {
  return (
    <div className="relative">
      <Image image={Mural} alt="Mural de Circclo" className="" />
      <div className="w-[90vw] md:w-[60vw] mx-auto mt-5 md:mt-0 lg:-mt-20 text-center text-neutral-700">
        {/* absolute left-1/2 -translate-x-1/2 bottom-0 */}
        <Text className="text-lg">
          Estamos liderando la revolución del consumo, desarrollamos tecnologia
          y asi habilitamos la transición hacia un modelo circular.
        </Text>
        <Text className="font-bold text-lg">
          Facilitamos a las marcas y organizaciones la adopción de envases
          reutilizables, transformando su impacto ambiental de manera
          exponencial.
        </Text>
      </div>
    </div>
  );
};

export default OurHistory;
