import React, { FC, InputHTMLAttributes } from "react";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClass?: string;
  inputClass?: string;
  labelClass?: string;
  labelText?: string;
  children?: React.ReactNode;
}

const Input: FC<InputProps> = ({
  containerClass,
  inputClass,
  labelText,
  labelClass,
  children,
  ...rest
}) => {
  return (
    <div className={containerClass}>
      {labelText && <label className={labelClass}>{labelText}</label>}
      <input className={inputClass} {...rest} />
      {children}
    </div>
  );
};

export default Input;
