import Image from "../atoms/Image";
import Text from "../atoms/Text";
import { MoonshotTexts } from "../particles/DataLists";
import Line from "./../../assets/line-moonshot.svg";

const OurMoonshotSection = () => {
  return (
    <section className="relative">
      <div className="mx-auto text-center">
        <div className="relative">
          <Image
            image={Line}
            alt={"line"}
            className="hidden lg:block absolute bottom-0 right-10 w-[30vw] "
            style={
              {
                // transform: "scaleY(-1) rotate(270deg)",
              }
            }
          />
          <Text className="text-figtree text-xl mt-16 mb-10 md:my-20">
            {MoonshotTexts.firstText}
          </Text>
        </div>

        <div className="w-[90vw] md:w-[50vw] mx-auto">
          <Text className="font-mohave text-color2 font-medium text-2xl md:text-5xl py-5 md:py-10">
            {MoonshotTexts.secondText}
          </Text>
          <Text className="">{MoonshotTexts.thirdText}</Text>
        </div>
      </div>

      <div className="bg-circcloOrange flex items-center justify-around text-white gap-16 text-xl md:text-3xl font-mohave font-bold py-5 my-16">
        {Array.from({ length: 3 }).map((_, index) => (
          <Text key={index} as="span" className="banner-text whitespace-nowrap">
            {MoonshotTexts.bannerText}
          </Text>
        ))}
      </div>
    </section>
  );
};

export default OurMoonshotSection;
