import Image from "../atoms/Image";
import bgImage from "../../assets/HeroVector.png";
import heroImage from "../../assets/hero-img.png";
import Img0 from "../../assets/hero-images/0.png";
import Img1 from "../../assets/hero-images/1.png";
import Img2 from "../../assets/hero-images/2.png";
import Img3 from "../../assets/hero-images/3.png";
import Img4 from "../../assets/hero-images/4.png";
import Img5 from "../../assets/hero-images/5.png";
import Img6 from "../../assets/hero-images/6.png";
import Text from "../atoms/Text";
import { HeroTexts } from "../particles/DataLists";
import Button from "../atoms/Button";
import { Fade, Slide } from "react-awesome-reveal";
import { useState, useEffect } from "react";

const heroImages = [Img0, Img1, Img2, Img3, Img4, Img5, Img6];

const HeroSection = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % 7);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className="py-16 w-full min-h-screen relative overflow-x-hidden flex justify-end">
      <Image
        className="absolute top-0 right-0 mt-10 w-[80vw] h-[50vh] lg:h-[90vh] lg:w-[60vw] md:h-[80vh] md:w-[50vw]"
        image={bgImage}
        alt="Hero Background Vector"
        objectCover="cover"
      />
      <main className="w-full lg:h-full flex items-center flex-col-reverse md:flex-row lg:px-24 md:px-8 px-5 pt-24 md:pt-32 lg:pt-0">
        <div className="py-5 flex flex-col justify-center md:gap-6 gap-3">
          <Text
            as="p"
            className="text-color1  uppercase tracking-widest lg:text-base  text-sm font-normal"
          >
            <Fade>{HeroTexts.firstText}</Fade>
          </Text>
          <Text
            as="h1"
            className="text-color3 lg:text-6xl md:text-5xl text-3xl font-medium font-mohave"
          >
            <Fade>{HeroTexts.secondText}</Fade>
          </Text>
          <Text
            as="h1"
            className="text-color5 lg:-mt-8 -mt-4 lg:text-9xl md:text-6xl text-5xl font-medium font-vina"
          >
            <Fade>{HeroTexts.thirdText}</Fade>
          </Text>
          <Text
            as="p"
            className="text-color3 md:text-lg  text-justify font-light"
          >
            <Fade>{HeroTexts.fourthText}</Fade>
          </Text>
          <div className="w-full flex md:justify-start justify-between items-center lg:gap-12 md:gap-6 gap-0">
            <a href="/contact">
              <Button
                type="button"
                className="outline-none border-none lg:px-7 px-5 py-3 bg-color2 text-white font-bold rounded-full"
              >
                {HeroTexts.firstButton}
              </Button>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-[50vw] md:h-[85vh]">
          <Image
            objectCover=""
            image={heroImages[currentImage]}
            alt="Hero Image"
            className="z-20 lg:w-[40vw] md:w-[50vw] w-[400px]"
          />
        </div>
      </main>
    </section>
  );
};

export default HeroSection;
