import Button from "../atoms/Button";
import Image from "../atoms/Image";
import Text from "../atoms/Text";
import ValueCard from "../molecules/ValueCard";
import { ValuesPropTexts } from "../particles/DataLists";
import Bg from "./../../assets/value-prop-bg.svg";

const ValuesProp = () => {
  return (
    <section className="relative">
      <div className="relative">
        <Image
          objectCover="cover"
          image={Bg}
          className="w-[200vw] -translate-x-[100vw] md:w-[180vw] md:-translate-x-[80vw] lg:w-screen lg:translate-x-0"
          alt="Value props background"
        />
        <ValueCard className="absolute z-20 top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80vw] md:w-[60vw] lg:translate-x-0 lg:w-[35vw] lg:ml-[5vw]" />
      </div>
      <main className="lg:absolute top-1/2 left-24 lg:-translate-y-1/2 z-10">
        <div className="lg:w-1/2 p-10 lg:pr-10 flex flex-col items-start space-y-10 text-color5">
          <div className="space-y-5">
            <Text
              as="h3"
              className="font-mohave font-semibold text-color5 lg:text-white  text-5xl"
            >
              {ValuesPropTexts.firstText}
            </Text>
            <Text
              as="h4"
              className="font-figtree font-light text-color3 lg:text-white text-xl"
            >
              {ValuesPropTexts.secondText}
            </Text>
          </div>
          <a href="/contact">
            <Button className="px-5 py-3 rounded-full bg-circcloOrange text-white text-xl font-semibold">
              {ValuesPropTexts.buttonText}
            </Button>
          </a>
        </div>
      </main>
    </section>
  );
};

export default ValuesProp;
