import React, { useCallback } from "react";
import Image from "../atoms/Image";
import Text from "../atoms/Text";
import { IntegralPropCardType, IntegralPropTexts, IntegralPropTextsType } from "../particles/DataLists";
import GroupOfPlus from "../../assets/plusGroup.png";
import Card from "../molecules/Card";
import Icon1 from "../../assets/icon1.png";
import Icon2 from "../../assets/icon2.png";
import Icon3 from "../../assets/icon3.png";
import { Fade } from "react-awesome-reveal";

const IntegralProp = () => {
  const renderServiceIcon = useCallback((element: number) => {
    switch (element) {
      case 0:
        return Icon1;
      case 1:
        return Icon2;
      case 2:
        return Icon3;
      default:
        return "";
    }
  }, []);

  return (
    <section className="w-full h-auto flex flex-col items-center justify-center relative lg:px-24 md:px-20 px-8">
      {/* <Image image={GroupOfPlus} alt="Vector" className="absolute top-0 right-4 lg:h-36 h-24" /> */}
      <div className="w-full bg-color2 rounded-full px-12 py-20 flex flex-col gap-3 items-center justify-center">
        <Text
          as="h2"
          className="md:text-4xl font-mohave text-2xl font-medium text-center text-white mb-10"
        >
          <Fade>{IntegralPropTexts.firstText}</Fade>
        </Text>
      </div>
      <div className="w-full h-auto -mt-16 flex items-center justify-center flex-wrap lg:gap-7 md:gap-10 gap-7 my-12 z-20 md:px-8 md:px-16">
        {IntegralPropTexts.cards.map((card: IntegralPropCardType, index: number) => (
          <Card
            cardClass="w-[22rem] h-[22rem] bg-neutral-100 flex flex-col items-center justify-center md:p-10 cursor-pointer transition duration-300 hover:shadow-xl  rounded-full cardPseudo "
            imageWrapperClass="w-[22rem] h-[22rem] absolute top-0 z-10"
            cover="object-cover"
            imageAlt={card.firstText}
            imageSrc={renderServiceIcon(index)}
            textWrapperClass="w-full flex flex-col items-center gap-2 pt-[8rem]"
            key={index}
          >
            <Text
              as="p"
              className="text-xs font-light text-center text-color3 px-10"
            >
              {card.secondText}
            </Text>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default IntegralProp;
