import ContactForm from "../molecules/ContactForm";
import ContactSection from "../organisms/ContactSection";

const Contact = () => {
  return (
    <>
      <ContactSection />
    </>
  );
};

export default Contact;
