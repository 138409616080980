import HeroSection from "../organisms/HeroSection";
import World from "../organisms/World";
import IntegralProp from "../organisms/IntegralProp";
import OurProps from "../organisms/OurProps";
import ValuesProp from "../organisms/ValuesProp";

const Home = () => {
  return (
    <>
      <HeroSection />
      <World />
      <IntegralProp />
      <ValuesProp />
      <OurProps />
    </>
  );
};

export default Home;
