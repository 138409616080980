import SolutionCard from "../molecules/SolutionCard";
import OurSolutions from "../organisms/OurSolutions";
import { SolutionsSections } from "../particles/DataLists";

const Solutions = () => {
  return (
    <>
      <OurSolutions />
      <div className="flex flex-col -space-y-20 mb-20">
        {SolutionsSections.map((section, index) => (
          <SolutionCard key={index} {...section} />
        ))}
      </div>
    </>
  );
};

export default Solutions;
