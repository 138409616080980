import Text from "../atoms/Text";
import Universidades from "../../assets/universidades-card.png";
import Marcas from "../../assets/marcas-card.png";
import Cines from "../../assets/cines-card.png";
import Image from "../atoms/Image";
import { SolutionsSection } from "../particles/DataLists";
import Button from "../atoms/Button";
import { useCallback } from "react";

const SolutionCard = ({
  firstText,
  secondText,
  buttonText,
  buttonHref,
  buttonBackgroundColor,
  buttonTextColor,
  direction,
  backgroundColor,
  hasStroke,
  strokeColor,
  imageName,
}: SolutionsSection) => {
  const renderImage = useCallback((element: string) => {
    switch (element) {
      case "universidades":
        return Universidades;
      case "marcas":
        return Marcas;
      case "cines":
        return Cines;
    }
  }, []);

  return (
    <div
      className={`relative w-screen h-[900px] md:h-[600px] bg-transparent`}
      id={imageName}
    >
      <div
        className={`absolute top-1/2 -translate-y-1/2 ${direction}-0 ${
          hasStroke ? "z-40" : "z-10"
        }`}
      >
        <svg
          width="100%"
          height="595"
          viewBox="0 0 1441 595"
          preserveAspectRatio="none"
          fill={backgroundColor}
          xmlns="http://www.w3.org/2000/svg"
          className={`relative w-[200vw] md:w-[90vw] lg:w-[80vw] h-[900px] md:h-[600px] `}
          style={{
            rotate: direction === "left" ? "180deg" : "0deg",
          }}
        >
          <path
            d="M2 297.011C2 134.087 134.076 2.01074 297 2.01074H1512V592.011H297C134.076 592.011 2 459.935 2 297.011Z"
            fill={backgroundColor}
            stroke={hasStroke ? strokeColor : ""}
            strokeWidth={hasStroke ? 4 : 0}
          />
        </svg>
        <div
          className={`absolute top-1/2 -translate-y-1/2 ${direction}-0 w-screen md:w-[90vw]  lg:w-[80vw] z-20 flex 
            ${
              direction === "left"
                ? "flex-col md:flex-row-reverse md:pr-24"
                : "flex-col md:flex-row md:pl-24"
            } 
            items-center  p-16 gap-10`}
        >
          <div className="flex flex-col gap-5 flex-1">
            <h3
              className="text-5xl font-mohave font-bold"
              style={{
                color: backgroundColor === "transparent" ? strokeColor : "#fff",
              }}
            >
              {firstText}
            </h3>
            <h3
              className=""
              style={{
                color: backgroundColor === "transparent" ? "black" : "white",
              }}
            >
              {secondText}
            </h3>
            <a href={buttonHref}>
              <Button
                className={`py-3 px-5 rounded-full`}
                style={{
                  backgroundColor: buttonBackgroundColor,
                  color: buttonTextColor,
                }}
              >
                {buttonText}
              </Button>
            </a>
          </div>
          <img
            src={renderImage(imageName)}
            alt=""
            className={`flex-2 h-[200px] md:h-[500px] w-[95vw] md:w-[40vw] object-cover rounded-[2.5rem]`}
          />
        </div>
      </div>
    </div>
  );
};

export default SolutionCard;
