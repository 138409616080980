import React, { useCallback } from "react";
import Image from "../atoms/Image";
import ValueCardBg from "../../assets/value-card-bg.svg";
import MetricCostos from "../../assets/metrics/metric-costos.svg";
import MetricGestion from "../../assets/metrics/metric-gestion.svg";
import MetricIngresos from "../../assets/metrics/metric-ingresos.svg";
import MetricSostenibilidad from "../../assets/metrics/metric-sostenibilidad.svg";
import MetricTrazabilidad from "../../assets/metrics/metric-trazabilidad.svg";
import Arrow from "../../assets/metrics/arrow.svg";
import CircleText from "../../assets/circle-text.png";
import { useState } from "react";

const ValueCard = ({ className }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const renderValueIcon = useCallback((element) => {
    switch (element) {
      case 0:
        return MetricCostos;
      case 1:
        return MetricGestion;
      case 2:
        return MetricIngresos;
      case 3:
        return MetricSostenibilidad;
      case 4:
        return MetricTrazabilidad;
    }
  }, []);

  const handlePreviousImage = () => {
    setCurrentImage((prev) => {
      if (prev === 0) {
        return 4;
      } else {
        return prev - 1;
      }
    });
  };

  const handleNextImage = () => {
    setCurrentImage((prev) => {
      if (prev === 4) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  return (
    <div className={className}>
      <Image
        image={Arrow}
        onClick={handlePreviousImage}
        className="w-12 z-40 absolute top-1/2 left-0 ml-5 rotate-180 -translate-y-1/2 cursor-pointer"
      />
      <Image
        image={Arrow}
        onClick={handleNextImage}
        className="w-12 z-40 absolute top-1/2 right-0 mr-5 -translate-y-1/2 cursor-pointer"
      />
      <Image
        image={CircleText}
        className="w-[90vw] md:w-[70vw] lg:w-[40vw] z-30 absolute -top-8 -left-8 md:-top-12 md:-left-12"
      />
      <Image
        image={renderValueIcon(currentImage)}
        className="absolute w-[70vw] md:w-[50vw] lg:w-[25vw] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20"
      />
      <Image image={ValueCardBg} />
    </div>
  );
};

export default ValueCard;
