import Text from "../atoms/Text";
import Cines from "../../assets/cines.png";
import Marcas from "../../assets/marcas.png";
import Universidades from "../../assets/universidades.png";
import Image from "../atoms/Image";
import Button from "../atoms/Button";
import { SolutionsHeroTexts } from "../particles/DataLists";
import { useCallback } from "react";

const OurSolutions = () => {
  const renderImage = useCallback((element: number) => {
    switch (element) {
      case 0:
        return Universidades;
      case 1:
        return Marcas;
      case 2:
        return Cines;
    }
  }, []);

  return (
    <section className="mt-24  min-h-[80vh]">
      <div className="lg:px-20 md:px-12 px-6 -mb-20 md:-mb-10 lg:-mb-24">
        <Text
          as="h2"
          className="text-color5 text-7xl md:text-7xl lg:text-[8rem] font-vina"
        >
          SOLUCIONES
        </Text>
      </div>
      <div className="w-screen flex flex-col md:flex-row -space-y-24 md:space-y-0 md:-space-x-16 lg:-space-x-[10vh]">
        {SolutionsHeroTexts.cards.map((card, index) => {
          return (
            <div className="w-full relative" key={index}>
              <Image
                image={renderImage(index)}
                className=""
                objectCover=""
                alt=""
              />
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center space-y-5">
                <Text
                  as="h3"
                  className="text-white font-bold text-3xl md:text-2xl lg:text-3xl md:w-[15vw] text-center font-mohave"
                >
                  {card.firstText}
                </Text>
                <a href={card.buttonHref}>
                  <Button className="rounded-full py-3 px-8 bg-color2 text-white font-bold">
                    {card.secondText}
                  </Button>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OurSolutions;
