export type NavLink = {
  name: string;
  url: string;
};

export const NavLinks: NavLink[] = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Soluciones",
    url: "/solutions",
  },
  {
    name: "Quienes Somos",
    url: "/history",
  },
];

export type NavButton = {
  name: string;
  url: string;
};

export const NavButtons: NavButton[] = [
  {
    name: "Contáctanos",
    url: "/contact",
  },
];

export type HeroTexts = {
  firstText: string;
  secondText: string;
  thirdText: string;
  fourthText: string;
  firstButton: string;
  secondButton: string;
};

export const HeroTexts: HeroTexts = {
  firstText: "SOLUCIONES PARA GESTIÓN DE ENVASES REUTILIZABLES",
  secondText: "Envases sostenibles,",
  thirdText: "IMPACTO GLOBAL",
  fourthText:
    "Revolucionamos la economía circular con tecnología API, haciendo que los envases reutilizables sean rentables y sostenibles. ¡Sumate a CIRCCLO hoy y eliminá los residuos, reduciendo la huella de carbono de tus envases descartables!",
  firstButton: "Conocer mas",
  secondButton: "Ver Video",
};

export type IntegralPropCardType = {
  firstText: string;
  secondText: string;
};

export type IntegralPropTextsType = {
  firstText: string;
  cards: IntegralPropCardType[];
};

export const IntegralPropTexts: IntegralPropTextsType = {
  firstText:
    "Circclo facilita la transición de las empresas hacia productos reutilizables",
  cards: [
    {
      firstText: "OPORTUNIDAD",
      secondText:
        "Optimizamos la economía y eficiencia de los envases reutilizables. Nuestra plataforma no solo reduce costos, sino que también impulsa el revenue de los negocios.",
    },
    {
      firstText: "LANZAMOS",
      secondText:
        "Nuestra tecnología de trazabilidad está lista para acompañarte en cada paso de tu transformación hacia la sostenibilidad.",
    },
    {
      firstText: "ESCALAMOS",
      secondText:
        "Con datos, potenciamos tus activos reutilizables, mejorando finanzas, operaciones y la conexión con tus clientes. Nuestro software busca alargar la vida de los envases y decirle chau al plástico descartable.",
    },
  ],
};

export type WorldTexts = {
  firstText: string;
  secondText: string;
};

export const WorldTexts: WorldTexts = {
  firstText: "LA CRUDA REALIDAD",
  secondText: "USAMOS, TIRAMOS Y CONTAMINAMOS",
};

export type OurPropsCardType = {
  firstText: string;
  secondText: string;
  ctaText: string;
  image: string;
  color: string;
};

export type OurPropsTextsType = {
  firstText: string;
  cards: OurPropsCardType[];
};

export const OurPropsTexts: OurPropsTextsType = {
  firstText: "NUESTRA PROPUESTA",
  cards: [
    {
      firstText: "Digitalizamos",
      secondText:
        "Integramos códigos QR especiales en tus productos reutilizables para hacerlos inteligentes.",
      ctaText: "Conocé más",
      image: "/assets/prop-1.png",
      color: "#23BC4E",
    },
    {
      firstText: "App Circclo",
      secondText:
        "Con nuestra app interactiva se pueden trackear los envases, la actividad de tu cliente y de tu local.",
      ctaText: "Conocé más",
      image: "/assets/prop-2.png",
      color: "#FF7640",
    },
    {
      firstText: "C. Analytics",
      secondText:
        "Incrementá tus métricas de impacto positivo y detectá potenciales mejoras en el mercado que te encuentres.",
      ctaText: "Conocé más",
      image: "/assets/prop-3.png",
      color: "#405CDC",
    },
  ],
};

export type ValuesPropTexts = {
  firstText: string;
  secondText: string;
  buttonText: string;
};

export const ValuesPropTexts: ValuesPropTexts = {
  firstText:
    "Te proporcionamos todo para que manejes tu sistema de reutilización",
  secondText:
    "Con nuestra plataforma fácil de usar, controla cada detalle de tu operación, además de ver cómo tu elección por la reutilización genera impactos positivos.",
  buttonText: "Conocé más",
};

export type NewsletterTexts = {
  firstText: string;
  placeholderText: string;
  buttonText: string;
};

export const NewsletterTexts: NewsletterTexts = {
  firstText:
    "Subscribe to get information, latest news and other interesting offers about Jadoo.",
  placeholderText: "Your email",
  buttonText: "Subscribe",
};

export type FooterLink = {
  name: string;
  url: string;
};

export type FooterLinksSection = {
  caption: string;
  links: FooterLink[];
};

export type FooterTexts = {
  underLogoText: string;
  quickLinks: FooterLinksSection;
  contacts: FooterLinksSection;
  more: FooterLinksSection;
};

export const FooterTexts: FooterTexts = {
  underLogoText: "Creando un mejor mañana, una reutilización a la vez.",
  quickLinks: {
    caption: "Quick Links",
    links: [
      {
        name: "Quienes somos",
        url: "/history",
      },
      {
        name: "Soluciones",
        url: "/solutions",
      },
    ],
  },
  contacts: {
    caption: "Contacto",
    links: [
      {
        name: "Contactanos",
        url: "/contact",
      },
    ],
  },
  more: {
    caption: "Recursos",
    links: [
      {
        name: "Política de privacidad",
        url: "/",
      },
      {
        name: "Términos y condiciones",
        url: "/",
      },
    ],
  },
};

export type SolutionsHeroCardType = {
  firstText: string;
  secondText: string;
  buttonHref: string;
};

export type SolutionsHeroTextsType = {
  firstText: string;
  cards: SolutionsHeroCardType[];
};

export const SolutionsHeroTexts: SolutionsHeroTextsType = {
  firstText: "SOLUCIONES",
  cards: [
    {
      firstText: "UNIVERSIDADES Y CENTROS CORPORATIVOS",
      secondText: "Conocé más",
      buttonHref: "#universidades",
    },
    {
      firstText: "MARCAS Y EMPRESAS DE RETORNABILIDAD",
      secondText: "Conocé más",
      buttonHref: "#marcas",
    },
    {
      firstText: "CINES Y FESTIVALES",
      secondText: "Conocé más",
      buttonHref: "#cines",
    },
  ],
};

export type SolutionsSection = {
  firstText: string;
  secondText: string;
  buttonText: string;
  buttonHref: string;
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  hasStroke: boolean;
  strokeColor: string;
  direction: "left" | "right";
  imageName: string;
};

export const SolutionsSections: SolutionsSection[] = [
  {
    firstText: "UNIVERSIDADES Y CENTROS CORPORATIVOS",
    secondText:
      "¡Alcanzá tus objetivos de sostenibilidad! Y demostrá a tus alumnos, empleados y docentes que el cuidado del planeta es parte de la agenda de la universidad y de la empresa! Podemos ofrecerte una solución tanto para llevar como para consumir en el lugar.",
    buttonText: "Conocé más",
    buttonHref: "#",
    buttonBackgroundColor: "#0429D1",
    buttonTextColor: "#fff",
    backgroundColor: "#23BC4E",
    hasStroke: false,
    strokeColor: "",
    direction: "right",
    imageName: "universidades",
  },
  {
    firstText: "MARCAS Y EMPRESAS DE RETORNABILIDAD",
    secondText:
      "Ayudamos a las empresas a maximizar la utilización de envases retornables, impulsando un crecimiento empresarial a través de los datos que provee nuestro software de seguimiento.",
    buttonText: "Conocé más",
    buttonHref: "#",
    buttonBackgroundColor: "#23BC4E",
    buttonTextColor: "#fff",
    backgroundColor: "transparent",
    hasStroke: true,
    strokeColor: "#F05204",
    direction: "left",
    imageName: "marcas",
  },
  {
    firstText: "CINES Y FESTIVALES",
    secondText:
      "En cines o eventos, también nos hacemos cargo para que te diviertas sin desperdicios.",
    buttonText: "Conocé más",
    buttonHref: "#",
    buttonBackgroundColor: "#F05204",
    buttonTextColor: "#fff",
    backgroundColor: "#0429D1",
    hasStroke: false,
    strokeColor: "",
    direction: "right",
    imageName: "cines",
  },
];

export type OurHistoryCardType = {
  title: string;
  titleColor: string;
  titleBackgroundColor: string;
  titlePosition: "left" | "right";
  firstText: string;
  secondText?: string;
  buttonText: string;
  buttonHref: string;
  cardImage: string;
  linePosition: "left" | "right" | null;
};

export const OurHistoryCards: OurHistoryCardType[] = [
  {
    title: "2019",
    titleColor: "#F05204",
    titleBackgroundColor: "transparent",
    titlePosition: "right",
    firstText:
      "Aprendimos de primera mano lo que se necesitaba para implementar programas de envases reutilizables eficientes. A través de nuestra plataforma, producto y experiencia, brindamos a nuestros clientes las herramientas para tener éxito.",
    secondText:
      "Iniciamos como operadores de un mercado online 100% sostenible. Entregábamos los productos a los clientes en envases especiales retornables, al estilo del lechero. Creando asi nuestra primera marca:",
    buttonText: "",
    buttonHref: "",
    cardImage: "team",
    linePosition: "right",
  },
  {
    title: "CIRCCLO HOME",
    titleColor: "#fff",
    titleBackgroundColor: "#0429D1",
    titlePosition: "left",
    firstText:
      "Aprendimos de primera mano lo que se necesitaba para implementar programas de envases reutilizables eficientes. A través de nuestra plataforma, producto y experiencia, brindamos a nuestros clientes las herramientas para tener éxito.",
    buttonText: "Conocé Circclo Home",
    buttonHref: "/contact",
    cardImage: "envase",
    linePosition: null,
  },
];

export const WhySectionTexts = {
  firstText: "¿POR QUÉ LO HACEMOS?",
  secondText:
    "Creamos tecnología personalizada para gestionar nuevos sistemas sostenibles",
  thirdText:
    "Desde el cumplimiento y el seguimiento, hasta la experiencia del cliente, nuestro paquete de software ayuda a gestionar y optimizar cada paso del programa de envases reutilizables.",
};

export const MoonshotTexts = {
  firstText: "NUESTRO MOONSHOT",
  secondText:
    "Hoy, nuestra plataforma permite que cualquier empresa ofrezca un programa de envases reutilizables.",
  thirdText:
    "El objetivo es simple: garantizar que todas las marcas puedan optar por formar parte de la economía circular y dejar atrás la contaminación de nuestro planeta. Nuestra plataforma tecnológica elimina los puntos ciegos y los obstáculos, desbloqueando así todo el potencial e impacto de la circularidad.",
  bannerText: "NO MÁS EXCUSAS NI TIEMPO QUE PERDER",
};
