import React, { useState } from "react";
import Input from "../atoms/Input";
import Text from "../atoms/Text";
import Image from "../atoms/Image";
import bgImage from "./../../assets/HeroVector.png";
import emailjs from "@emailjs/browser";

const inputs: Array<{
  type: string;
  name: string;
  required: boolean;
  placeholder: string;
}> = [
  {
    type: "text",
    required: false,
    name: "companyName",
    placeholder: "Empresa",
  },
  {
    type: "text",
    required: true,
    name: "city",
    placeholder: "Ciudad",
  },
  {
    type: "text",
    required: true,
    name: "name",
    placeholder: "Nombre Completo",
  },
  {
    type: "email",
    required: true,
    name: "email",
    placeholder: "Correo Electrónico",
  },
  {
    type: "tel",
    required: true,
    name: "phoneNumber",
    placeholder: "Número de Teléfono",
  },
];

const ContactForm: React.FC = () => {
  const [formError, setFormError] = useState("");
  const [formData, setFormData] = useState({
    companyName: "",
    city: "",
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    newsletter: false,
  });

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_f9raj9g",
        "template_qetp8tl",
        e.target as HTMLFormElement,
        { publicKey: "OO2BGsinNImfR4eQF" }
      )
      .then(
        (result) => {
          // console.log(result.text);
          setFormData({
            companyName: "",
            city: "",
            name: "",
            email: "",
            phoneNumber: "",
            message: "",
            newsletter: false,
          }); // Clear form
        },
        (error) => {
          console.log(error.text);
          setFormError("Error al enviar el formulario");
        }
      );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target as HTMLInputElement;
    const inputValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: inputValue,
    }));
  };

  return (
    <div>
      <Image
        className="hidden md:block absolute bottom-0 left-0 rotate-180 mt-10 w-[80vw] h-[50vh] md:h-[40vh] md:w-[40vw] lg:h-[60vh] lg:w-[30vw] "
        image={bgImage}
        alt="Hero Background Vector"
        objectCover="cover"
      />

      <form
        onSubmit={handleFormSubmit}
        className="relative z-20 bg-white rounded shadow-md px-5 py-8 "
      >
        <Text
          as="h2"
          className="text-5xl text-vina text-center text-color5 font-bold mb-5"
        >
          Solicitar Información
        </Text>
        <div className="flex flex-col gap-5 pb-5">
          {inputs.map((input, index) => (
            <Input
              required={input.required}
              key={index}
              type={input.type}
              name={input.name}
              containerClass={`flex w-[90vw] md:w-[50vw] lg:w-[40vw] ${
                input.type === "checkbox"
                  ? "flex-row-reverse gap-5 justify-end"
                  : "flex-col"
              }`}
              labelClass="text-gray-800"
              inputClass="border-2 border-gray-150 p-2 rounded mt-2 focus:outline-none focus:border-color2 transition-colors duration-300 ease-in-out"
              labelText={input.placeholder}
              value={formData[input.name as keyof typeof formData].toString()}
              onChange={handleInputChange}
            />
          ))}
          <textarea
            name="message"
            className="order- border-2 border-gray-150 p-2 rounded mt-2 focus:outline-none focus:border-color2 transition-colors duration-300 ease-in-out"
            placeholder="Mensaje"
          />
          <Input
            type={"checkbox"}
            name={"newsletter"}
            containerClass={`flex w-[90vw] md:w-[50vw] lg:w-[40vw] flex-row-reverse gap-5 justify-end`}
            labelClass="text-gray-800"
            inputClass="border-2 border-gray-150 p-2 rounded mt-2 focus:outline-none focus:border-color2 transition-colors duration-300 ease-in-out"
            labelText={
              "Me gustaría recibir información sobre sus productos y servicios."
            }
            value={formData["newsletter" as keyof typeof formData].toString()}
            onChange={handleInputChange}
          />
        </div>
        <button
          type="submit"
          className="bg-color2 text-white px-5 py-2 rounded-full self-center"
        >
          Enviar
        </button>
        {formError && <p className="color-destructive">{formError}</p>}
      </form>
    </div>
  );
};

export default ContactForm;
