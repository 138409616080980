import { useCallback } from "react";
import TeamImage from "../../assets/team-history.png";
import EnvasesImage from "../../assets/envases-history.png";
import LineRightBottom from "../../assets/line-right-bottom.svg";
import LineLeftBottom from "../../assets/line-left-bottom.svg";
import { OurHistoryCardType } from "../particles/DataLists";
import Button from "../atoms/Button";

const OurHistoryCard = ({
  title,
  titleColor,
  titleBackgroundColor,
  titlePosition,
  firstText,
  secondText,
  buttonText,
  buttonHref,
  cardImage,
  linePosition,
}: OurHistoryCardType) => {
  const renderImage = useCallback((element: string) => {
    switch (element) {
      case "team":
        return TeamImage;
      case "envase":
        return EnvasesImage;
      default:
        throw new Error("Invalid image name");
    }
  }, []);

  const renderLine = useCallback((element: string) => {
    switch (element) {
      case "right":
        return LineRightBottom;
      case "left":
        return LineLeftBottom;
      default:
        throw new Error("Invalid image name");
    }
  }, []);

  return (
    <div className="flex flex-col justify-start relative">
      {linePosition && (
        <img
          src={renderLine(titlePosition)}
          alt="Line"
          className="hidden lg:block absolute top-[10rem] w-[35vw] z-30"
          style={{
            left: titlePosition == "left" ? "5vw" : "unset",
            right: titlePosition == "right" ? "20vw" : "unset",
            width: titlePosition == "right" ? "35vw" : "32vw",
          }}
        />
      )}
      <div className="md:-mb-5 flex z-40">
        <h2
          className="font-vina text-[4rem] md:text-[8rem] relative"
          style={{
            color: titleColor,
            backgroundColor: titleBackgroundColor,
            margin: titlePosition == "left" ? "0 auto 0 0" : "0 0 0 auto",
            borderRadius:
              titlePosition == "left" ? "0 15rem 15rem 0" : "15rem 0 0 15rem",
            // paddingRight: titlePosition == "left" ? "10rem" : "0",
            paddingRight: "15vw",
            paddingLeft: "1rem",
          }}
        >
          {title}
        </h2>
      </div>
      <div
        className="relative w-screen md:w-[90vw]"
        style={{
          margin: titlePosition == "left" ? "0 0 0 auto" : "0 auto 0 0",
        }}
      >
        <div className="block md:absolute top-1/2 left-10 md:-translate-y-1/2 z-30 p-5 md:p-0 transition-all">
          <h3 className="text-black md:text-white md:w-[50vw] lg:w-[28vw] mb-5">
            {firstText}
          </h3>
          {/* {buttonHref && buttonText && (
            <a href={buttonHref}>
              <Button
                className="
                bg-white border-color5 text-color5 md:bg-transparent md:text-white md:border-white
                 px-5 py-3 rounded-full  border-2 font-bold cursor-pointer"
              >
                {buttonText}
              </Button>
            </a>
          )} */}
        </div>
        <div
          style={{
            borderRadius: titlePosition == "left" ? "15rem 0 0 15rem" : "0",
          }}
          className="hidden md:block absolute top-0 left-0 bg-gradient-to-r from-black to-transparent w-2/3 md:1/3 h-full"
        ></div>
        <img
          style={{
            borderRadius:
              titlePosition == "left" ? "15rem 0 0 15rem" : "0% 15rem 15rem 0%",
          }}
          className="w-full"
          src={renderImage(cardImage)}
          alt="Equipo de Circclo"
        />
      </div>
      {/* {secondText && (
        <div
          className="py-10 mx-auto w-[40vw] text-center"
          style={
            {
              // margin: titlePosition == "right" ? "0 0 0 auto" : "0 auto 0 0",
              // textAlign: titlePosition == "right" ? "right" : "left",
            }
          }
        >
          <p>{secondText}</p>
        </div>
      )} */}
    </div>
  );
};

export default OurHistoryCard;
