import Button from "../atoms/Button";
import Image from "../atoms/Image";
import Text from "../atoms/Text";
import { OurPropsCardType, OurPropsTexts } from "../particles/DataLists";
import Prop1 from "../../assets/prop-1.png";
import Prop2 from "../../assets/prop-2.png";
import Prop3 from "../../assets/prop-3.png";
import { useCallback } from "react";
import { Fade } from "react-awesome-reveal";

const OurProps = () => {
  const renderServiceIcon = useCallback((element: number) => {
    switch (element) {
      case 0:
        return Prop1;
      case 1:
        return Prop2;
      case 2:
        return Prop3;
      default:
        throw new Error("Invalid element value");
    }
  }, []);

  return (
    <section className="w-screen overflow-x-hidden h-auto flex flex-col gap-16 relative lg:px-24 md:px-20 px-8">
      <Text as="h2" className="font-vina text-6xl md:text-8xl  text-color5">
        {OurPropsTexts.firstText}
      </Text>
      <div className="flex flex-col lg:flex-row gap-10">
        {OurPropsTexts.cards.map((prop: OurPropsCardType, index: number) => {
          return (
            <Fade>
              <div className="">
                <div
                  key={index}
                  style={{ backgroundColor: prop.color }}
                  className="rounded-[24px] p-10 text-white space-y-5 flex flex-col items-start pb-24 md:pb-[15rem] "
                >
                  <Text as="h3" className="text-3xl  font-bold">
                    {prop.firstText}
                  </Text>
                  <Text as="h4" className="text-lg">
                    {prop.secondText}
                  </Text>
                  <a href="/contact">
                    <Button
                      className={`relative z-20 py-2 px-5 border-2 border-white rounded-full font-bold cursor-pointer`}
                    >
                      {prop.ctaText}
                    </Button>
                  </a>
                </div>
                <Image
                  image={renderServiceIcon(index)}
                  alt={prop.firstText}
                  className={`relative z-10 -mt-[10rem] lg:-mt-[20rem]`}
                  objectCover="object-contain"
                />
              </div>
            </Fade>
          );
        })}
      </div>
    </section>
  );
};

export default OurProps;
