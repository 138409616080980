import Image from "../atoms/Image";
import Text from "../atoms/Text";
import ImageBg from "./../../assets/why-section-bg.svg";
import Vaso from "./../../assets/why-vaso.png";
import Phone from "./../../assets/prop-2.png";
import { WhySectionTexts } from "../particles/DataLists";
import BottomLeftLine from "./../../assets/line-left-bottom.svg";
import EnvaseFirst from "./../../assets/why-envase-1.png";
import EnvaseSecond from "./../../assets/why-envase-2.png";

const OurWhySection = () => {
  return (
    <section className="relative z-40">
      <div className="mx-auto text-center">
        <div className="relative">
          <Image
            image={BottomLeftLine}
            alt={"line"}
            className="hidden lg:block absolute top-0 right-10 rotate-180 w-[30vw]"
          />
          <Text className="text-figtree text-xl mt-20 md:my-20">
            {WhySectionTexts.firstText}
          </Text>
          <Image
            image={BottomLeftLine}
            alt={"line"}
            className="hidden lg:block absolute bottom-0 left-10  w-[30vw]"
          />
        </div>

        <div className="w-[90vw] md:w-[50vw] mx-auto">
          <Text className="font-mohave text-color2 font-medium text-2xl md:text-5xl py-5 md:py-10">
            {WhySectionTexts.secondText}
          </Text>
          <Text className="">{WhySectionTexts.thirdText}</Text>
        </div>
      </div>

      <div className="relative lg:-mt-[10rem]">
        <Image image={ImageBg} alt="Our Why Background" className="" />
        <Image
          image={Vaso}
          alt="Our Why Background"
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[30%] md:-translate-y-[20%] w-[80vw] md:w-[50vw]"
        />
        <Image
          image={Phone}
          alt="Our Why Background"
          className="absolute top-[20%] md:top-[40%] left-[20%] md:left-[30%] -translate-x-1/2 -translate-y-[20%] w-[60vw] md:w-[38vw]"
        />
        <div className="hidden md:flex flex-col w-[30vw] absolute top-[40%] right-[0%]  -translate-y-[20%] -gap-5">
          <Image image={EnvaseFirst} alt="Our Why Background" className="" />
          <Image image={EnvaseSecond} alt="Our Why Background" className="" />
        </div>
      </div>
    </section>
  );
};

export default OurWhySection;
