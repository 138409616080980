import OurHistoryCard from "../molecules/OurHistoryCard";
import OurHistory from "../organisms/OurHistory";
import OurMoonshotSection from "../organisms/OurMoonshotSection";
import OurWhySection from "../organisms/OurWhySection";
import { OurHistoryCardType, OurHistoryCards } from "../particles/DataLists";

const History = () => {
  return (
    <>
      <OurHistory />
      <div className="flex flex-col gap-[5vw] mt-10">
        {OurHistoryCards.map((card: OurHistoryCardType, index) => {
          return <OurHistoryCard key={index} {...card} />;
        })}
      </div>
      <OurWhySection />
      <OurMoonshotSection />
    </>
  );
};

export default History;
