import { Fade } from "react-awesome-reveal";
import WorldImage from "../../assets/world.png";
import Image from "../atoms/Image";
import { WorldTexts } from "../particles/DataLists";

const World = () => {
  return (
    <div className="w-screen pb-20 md:py-20 px-[5vw] md:px-[20vw] flex flex-col items-center space-y-10">
      <div className="text-center">
        <h3 className="text-2xl font-figtree text-neutral-400">
          {WorldTexts.firstText}
        </h3>
        <h2 className="text-4xl md:text-7xl  font-vina text-neutral-500">
          {WorldTexts.secondText}
        </h2>
      </div>
      <Fade>
        <Image
          objectCover="cover"
          image={WorldImage}
          alt="World"
          className=""
        />
      </Fade>
    </div>
  );
};

export default World;
